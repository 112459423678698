/*
* expandable menu started
*/

@media (max-width: 1199px) {
  .header-navigation {
    margin-bottom: 0;
  }
  .logo-box {
    width: 100%;
    float: left;
    margin-top: 60px !important;
  }
  .navigation-box {
    margin: 0;
  }
  .navbar-expand-lg .menu-toggler {
    display: block;
    margin-right: 0;
    border-color: #fff;
    background-color: transparent !important;
    margin: 23px 0;
    color: #fff;
    border: none;
    font-size: 28px;
    float: right;
    outline: none;
    cursor: pointer;
  }
  .menu-toggler:hover {
    border-color: #ff4eb5;
  }
  .menu-toggler .icon-bar {
    background: #fff;
  }
  .menu-toggler:hover .icon-bar {
    background: #ff4eb5;
  }
  .navbar-expand-lg .navbar-collapse {
    padding-bottom: 0;
    overflow: auto !important;
    width: 100%;
  }
  .navbar-expand-lg .navbar-collapse {
    display: none !important;
    background: transparent;
    padding: 0px 0px !important;
    padding-bottom: 0px !important;
    margin: 0;
    background: #000;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    height: 0;
  }
  .navbar-expand-lg .navbar-collapse.showen {
    display: block !important;
    height: auto;
    max-height: 70vh;
  }
  .navbar-collapse.show {
    overflow-y: auto;
  }
  .header-navigation .main-navigation {
    float: none !important;
    text-align: left !important;
  }
  .header-navigation .nav {
    width: 100%;
    text-align: left;
  }
  .header-navigation .nav>li {
    display: block;
    padding: 0 !important;
    width: 100%;
    float: none;
  }
  .header-navigation .nav>li+li {
    margin-left: 0 !important;
  }
  .header-navigation .nav>li>a {
    padding: 8px 0;
  }
  .header-navigation .right-box.nav {
    width: 100%;
    margin: 0;
  }
  .header-navigation .right-box.nav>li>a {
    border: none !important;
    padding: 8px 0;
    color: #fff;
  }
  .header-navigation .nav>li>a:after {
    display: none;
  }
  .header-navigation .nav.navigation-box+.nav {
    border-top: 1px dashed #2a2833;
  }
  .header-navigation .navigation-box>li>.sub-menu, .header-navigation .navigation-box>li>.sub-menu>li>.sub-menu {
    position: relative !important;
    width: 100% !important;
    opacity: 1 !important;
    visibility: visible !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    -webkit-transition: none !important;
    transition: none !important;
    display: none;
    float: none !important;
    margin: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .header-navigation .navigation-box>li>.sub-menu>li>a::after {
    display: none;
  }
  .header-navigation ul.navigation-box>li+li {
    padding: 0 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: block;
    float: none;
    margin: 0 !important;
  }
  .header-navigation .nav>li.show-mobile {
    display: none;
  }
  .header-navigation ul.navigation-box>li>a, .header-navigation .right-box.nav>li>a {
    padding: 0;
    display: block;
    color: #fff;
    padding: 16px 0 16px 20px !important;
    -webkit-transition: background 0.4s ease 0s;
    transition: background 0.4s ease 0s;
  }
  .header-navigation ul.navigation-box li a .sub-nav-toggler {
    background-color: transparent;
    background-image: none;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 9px 9px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    /*margin-top: 8px;*/
    margin-right: 0px;
    display: block;
    cursor: pointer;
    outline: none;
  }
  .header-navigation .navigation-box .sub-nav-toggler .icon-bar {
    background-color: #fff;
    border-radius: 1px;
    display: block;
    height: 1px;
    width: 20px;
  }
  .header-navigation .navigation-box .sub-nav-toggler .icon-bar+.icon-bar {
    margin-top: 4px;
  }
  .header-navigation .container .right-side-box {
    top: 20px;
    right: 15px;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  .header-navigation .container {
    display: block;
    padding-right: 0px;
    padding-left: 0px;
    position: relative;
  }
  .header-navigation .container .logo-box {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    float: none;
    padding-right: 145px;
  }
  .header-navigation .container .logo-box .navbar-brand {
    float: left;
    padding: 20px 0;
  }
  .header-navigation .container .logo-box .navbar-toggler {
    float: left;
  }
  .header-navigation ul.navigation-box {
    display: block;
    margin: 0 !important;
    width: 100%;
  }
  .header-navigation ul.navigation-box>li {
    padding: 0;
    display: block;
  }
  .header-navigation ul.navigation-box>li>a {
    display: block;
    padding: 13px 30px;
  }
  .header-navigation ul.navigation-box>li>a:after {
    display: none;
  }
  .header-navigation ul.navigation-box>li+li {
    margin: 0;
  }
  .header-navigation .main-navigation {
    float: none;
    width: 100%;
    display: none;
    text-align: left;
    background: #18212E;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .header-navigation .container .menu-toggler {
    display: block;
  }
  .header-navigation .stricky-fixed ul.navigation-box>li {
    padding: 0;
  }
  .header-navigation .container .logo-box .navbar-brand {
    background-color: transparent;
  }
  .header-navigation .container .menu-toggler {
    float: left;
    margin: 28px 0;
    color: #2a2833;
    margin-left: 30px;
    position: relative;
  }
  .header-navigation .container .logo-box {
    padding-right: 15px !important;
  }
  .header-navigation {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-navigation .container .logo-box {
    left: 0 !important;
  }
  .header-navigation .container .logo-box {
    padding-left: 15px;
  }
  .header-navigation .main-navigation {
    padding-right: 0;
  }
  .header-navigation ul.navigation-box>li.current>a, .header-navigation ul.navigation-box>li:hover>a {
    color: #fff;
  }
  .site-header__header-two .header-navigation .container .menu-toggler {
    color: #fff;
  }
  .site-header__header-two .header-navigation ul.navigation-box>li>a {
    color: #fff;
  }
}

@media (max-width: 425px) {
  .header-navigation .container .right-side-box {
    display: none !important;
  }
  .header-navigation .container .menu-toggler {
    float: right !important;
  }
  .header-navigation .container .logo-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .header-navigation .container .logo-box:after {
    display: none;
  }
}

@media (max-width: 1199px) {
  .blog-one__content {
    padding-left: 37px;
    padding-right: 37px;
  }
  .cta-one__moc {
    left: -20%;
  }
  .cta-two__moc {
    right: -70px;
  }
  .banner-two__moc {
    right: -29%;
  }
}

@media (max-width: 991px) {
  img {
    max-width: 100%;
  }
  img {
    height: auto;
    max-width: 100%;
  }
  .fact-one__single {
    margin-bottom: 20px;
  }
  .app-shot-one .app-shot-one__carousel::before {
    display: none;
  }
  .app-shot-one .app-shot-one__carousel {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 20px;
  }
  .footer-widget__about {
    text-align: center;
  }
  .footer-widget {
    margin-bottom: 30px;
    padding: 0;
  }
  .footer-widget__links-wrap {
    padding-left: 15px;
  }
  .site-footer__upper {
    padding-bottom: 90px;
  }
  .site-footer__social {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .banner-one__moc {
    display: none;
  }
  .banner-one .container {
    padding-top: 200px;
    padding-bottom: 120px;
  }
  .cta-one__moc {
    position: relative;
    top: auto;
    left: auto;
  }
  .cta-two__moc {
    position: relative;
    top: auto;
    right: auto;
  }
  .pricing-one [class*=col-] {
    margin-bottom: 0;
  }
  .pricing-one [class*=col-]+[class*=col-] {
    border-left: 0;
    border-top: 1px solid #efefef;
    padding-top: 40px;
    margin-top: 40px;
  }
  .testimonials-one__single p br {
    display: none;
  }
  .testimonials-one__single {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .fact-one__single {
    margin-left: auto;
    margin-right: auto;
  }
  .banner-two__moc {
    right: -52%;
  }
}

@media (max-width: 767px) {
  .blog-details .share-block {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .blog-details .social-block {
    margin-top: 20px;
  }
  .comment-one__top-left, .comment-one__top-right, .blog-details__author-image, .blog-details__author-content {
    display: block;
  }
  .comment-one__top-right, .blog-details__author-content {
    padding: 0;
    padding-top: 20px;
  }
  .video-one__title {
    font-size: 30px;
  }
  .banner-two__moc {
    display: none;
  }
  .banner-two .container {
    padding-top: 150px;
  }
}

@media (max-width: 575px) {
  .footer-widget__links-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .footer-widget__about {
    text-align: left;
  }
  .inner-banner {
    padding-top: 150px;
    padding-bottom: 120px;
  }
}

@media (max-width: 480px) {
  .block-title__title br, .banner-one__text br, .banner-two__text br {
    display: none;
  }
  .block-title__title {
    font-size: 40px;
    line-height: 1.1em;
  }
  .comment-one__top-left, .comment-one__top-right, .comment-one__image, .comment-one__content, .blog-details__author-image, .blog-details__author-content {
    display: block;
    padding: 0;
  }
  .comment-one__content, .comment-one__top-right, .blog-details__author-content {
    margin-top: 20px;
  }
  .blog-details .blog-one__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .blog-details__author {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  [class*=banner-one__shape-], [class*=banner-two__shape-] {
    display: none;
  }
  .banner-one__moc {
    display: block;
    position: relative;
    top: auto;
    right: auto;
  }
  .banner-two__title, .banner-one__title {
    font-size: 50px;
  }
  .banner-two__title br, .banner-one__title br {
    display: none;
  }
}

@media (max-width: 375px) {
  .blog-one__meta li+li:before {
    margin-left: 3px;
    margin-right: 3px;
  }
  .block-title__title {
    font-size: 36px;
  }
  .site-footer__bottom .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .site-footer__social {
    margin-top: 15px;
  }
  .banner-one .container {
    padding-top: 130px;
  }
  .banner-one__moc:before {
    display: none;
  }
  .video-one__btn {
    width: 60px;
    height: 60px;
  }
  .video-one__btn i {
    font-size: 20px;
    line-height: 60px;
  }
  .video-one__title {
    font-size: 24px;
    margin-top: 15px;
  }
}

@media screen and (min-width:200px) and (max-width:680px) {
  .container {
    max-width: 450px;
  }
  .block-title__title {
    max-width: 450px;
    max-height: 50px;
  }
  .cta-two {
    position: relative;
  }
  .mailchimp-one {
    max-width: 480px;
    max-height: none;
  }
  /* .cta-one__content{
    max-width: 150px;
    max-height: 150px;
  } */
  .navigation-box {
    max-height: 25px;
  }
  .right-side-box {
    max-width: 100px;
    position: relative;
  }
  .logo-box {
    max-width: 200px;
  }
  .main-logo {
    width: 50% !important;
  }
  .header-navigation .container .logo-box {
    padding-bottom: 35px !important;
  }
  .ourSevices .h3, h3 {
    padding: 15px;
    font-size: 12px !important;
  }
  .img-guayness {
    width: 10% !important;
  }
  .container {
    display: flex;
  }
  .content-pa .img-lap {
    margin-top: 30px;
    margin-left: 30px;
    display: flex;
  }
  .brincos {
    position: absolute;
    bottom: 3%;
    right: 30px !important;
    width: 300px;
  }
  .brincos img {
    width: 230px !important;
  }
  .content-pa .img-lap {
    display: none;
  }
  .right-side-box {
    position: absolute;
    top: 50%;
    right: -5%;
  }
  .header-navigation .container .right-side-box {
    display: flex !important;
    top: 40px !important;
    right: 20px !important;
  }
  .thm-btn {
    border: none;
    display: inline-block;
    vertical-align: middle;
    outline: none;
    font-size: 14px !important;
    font-weight: 500;
    color: #2a2833;
    padding: 5px 12px !important;
    border-radius: 5px;
    -webkit-transition: background .4s ease, color .4s ease;
    transition: background .4s ease, color .4s ease;
    background-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
    background-image: linear-gradient( 90deg, #ff4eb5 0%, #ffa065 100%);
    position: relative;
  }
  .banner-one .container {
    padding-top: 80px !important;
    padding-bottom: 230px;
    position: relative;
  }
  .cta-one {
    padding: 25px 0 !important;
    position: relative;
  }
  .guay-title-s {
    padding-bottom: 20px !important;
  }
  .footer-widget__about {
    text-align: center;
  }
}

@media screen and (min-width:650px) and (max-width:799px) {
  .content-pa .img-lap {
    display: none;
  }
  .brincos {
    position: absolute;
    bottom: 3%;
    right: 30px !important;
    width: 300px;
  }
  .brincos img {
    width: 200px !important;
    padding-bottom: 180px;
  }
  .ourSevices .h3, h3 {
    padding: 5px;
    font-size: 12px !important;
  }
  .right-side-box {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-20%);
    right: -5%;
  }
  .header-navigation .container .right-side-box {
    display: flex !important;
    top: 60px !important;
    right: 20px !important;
  }
  .thm-btn {
    font-size: 25px !important;
  }
}

@media screen and (min-width:800px) and (max-width:1024px) {
  .right-side-box {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-20%);
    right: -5%;
  }
  .content-pa {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .content-pa .img-lap .cta-one__moc {
    max-width: 500px;
    margin-top: 100px;
    margin-left: 100px;
  }
  .ourSevices .h3, h3 {
    padding: 6px;
    font-size: 20px !important;
  }
  .header-navigation .container .right-side-box {
    display: flex !important;
    top: 60px !important;
    right: 20px !important;
  }
  .thm-btn {
    font-size: 25px !important;
  }
}

.main-logo {
  width: 185px;
}

.text-green-g {
  color: #92BC1C;
}

.right-side-box {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -5%;
}

@media screen and (max-width: 600px) and (min-width: 300px) {
  .header-navigation .container .logo-box .navbar-brand {
    float: left;
    padding: 0px !important;
  }
  .header-navigation .container .navbar-brand {
    height: auto;
    margin: 0;
    line-height: 0;
  }
  .clearfix .logo-box {
    margin-top: 10px !important;
  }
  .site-header__header-one {
    max-height: 70px !important;
    background: #fff !important;
  }
  .header-navigation .container .right-side-box {
    display: flex !important;
    top: 20px !important;
    right: 20px !important;
  }
  .brincos {
    position: absolute;
    bottom: 3%;
    right: 30px !important;
    width: 280px;
  }
}

@media screen and (max-width: 10240px) and (min-width: 768px) {
  .header-navigation .container .logo-box .navbar-brand {
    float: left;
    padding: 0px !important;
  }
  .header-navigation .container .navbar-brand {
    height: auto;
    margin: 0;
    line-height: 0;
  }
  .clearfix .logo-box {
    margin-top: 12px !important;
  }
  .site-header__header-one {
    max-height: 120px !important;
    background: #fff !important;
  }
  .header-navigation .container .right-side-box {
    display: flex !important;
    top: 20px !important;
    right: 20px !important;
  }
  .content-pa .img-lap .cta-one__moc {
    max-width: 450px;
    margin-top: 100px;
    margin-left: 200px;
  }
}

@media (min-width: 1025px) {
  .header-navigation .container .logo-box {
    float: left;
    position: absolute;
    top: 30%;
    left: 15px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
  .main-logo {
    width: 100px;
  }
  .clearfix .logo-box {
    margin-top: 0px !important;
  }
  .site-header__header-one {
    max-height: 80px !important;
    background: #fff !important;
  }
  .header-navigation .container .right-side-box {
    display: flex !important;
    top: 40px !important;
    right: 20px !important;
  }
}