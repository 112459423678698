.shop-group .list-group-item{
    border: 0px;
}
.shop-group .list-group-item:hover{
    background-color: #af2f72;
    color: white;
    font-weight: bold;
}
.heigth-list{
    height: 430px;
}
.margin-img{
    margin-top: 16%;
    margin-left: 23%;
}
.active-prod{
    background-color: #af2f72;
    color: white;
    font-weight: bold;
}
.cat2 .active-prod {
    background-color: #9cb537;
}
.cat2 .list-group-item:hover{
    background-color: #9cb537;
    color: white;
    font-weight: bold;
}