/***************************************************************************************************************
||||||||||||||||||||||||||||            MASTER STYLESHEET FOR DIMON         ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
||||||||||||||||||||||||||||              TABLE OF CONTENT                  ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
****************************************************************************************************************
* 1. common styles
* 2. header styles
* 3. banner styles
* 4. blog styles
* 5. sidebar styles
* 6. footer styles
* 7. inner-banner styles
* 8. video styles
* 9. brand styles
* 10. mailchimp styles
* 11. accrodion styles
* 12. fact styles
* 13. testimonials styles
* 14. pricing styles
* 15. cta styles
* 16. service styles
* 17. app-shot styles
****************************************************************************************************************
||||||||||||||||||||||||||||            End TABLE OF CONTENT                ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************/

/*
* 1. common styles
*/

/* body {
  font-family: 'Quicksand', sans-serif;
  color: #74727a;
  font-size: 18px;
  line-height: 34px;
} */

a:active, a:hover, a:focus, a:visited {
  text-decoration: none;
}

h1 {
  color: #15263B;
  font-weight: 700;
}

.txt-rotate {
  /* spna font-family: 'Quicksand', sans-serif; */
  color: #92BC1C;
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.thm-base-bg {
  background-color: #ff4eb5;
}

.thm-base-bg-2 {
  background-color: #ffa065;
}

.thm-gray-bg {
  background-color: #fefbf4;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.block-title {
  margin-bottom: 45px;
}

.block-title__title {
  margin: 0;
  margin-top: -10px;
  font-size: 50px;
  font-weight: 400;
  color: #2a2833;
  line-height: 1.2em;
}

.block-title__title span {
  font-weight: 700;
}

.thm-btn {
  border: none;
  display: inline-block;
  vertical-align: middle;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  color: #2a2833;
  padding: 13px 41px;
  border-radius: 5px;
  -webkit-transition: background .4s ease, color .4s ease;
  transition: background .4s ease, color .4s ease;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
  background-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  position: relative;
}

.thm-btn:before {
  content: '';
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.thm-btn span {
  position: relative;
}

.thm-btn:hover {
  color: #fff;
}

.thm-btn:hover:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.post-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-pagination a {
  border-radius: 50%;
  background-color: #fefbf4;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding: 8px 19px;
  color: #2a2833;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.post-pagination a:hover, .post-pagination a.active {
  background-color: #2a2833;
  color: #fff;
}

.post-pagination a+a {
  margin-left: 15px;
}

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.scroll-to-top {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-image: -webkit-gradient(linear, left top, right top, from(#AE2F72), color-stop(51%, #7A1F53), to(#AE2F72));
  background-image: linear-gradient(to right, #AE2F72 0%, #7A1F53 51%, #AE2F72 100%);
  background-size: 200% auto;
  position: fixed;
  bottom: 70px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: .4s;
  transition: .4s;
  display: block;
  border-radius: 50%;
}

.scroll-to-top i {
  color: #fff;
  font-size: 18px;
  line-height: 45px;
}

.scroll-to-top:hover {
  background-position: right center;
}

.scroll-to-top:hover i {
  color: #fff;
}

/*
* 2. header styles
*/

.header-navigation {
  background-color: transparent;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  padding: 0;
  position: relative;
  background-color: transparent;
}

.header-navigation .container {
  background: transparent;
  position: relative;
  display: block;
}

.header-navigation .container .logo-box {
  float: left;
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-navigation .container .navbar-brand {
  height: auto;
  margin: 0;
  line-height: 50;
}

.header-navigation .container .menu-toggler {
  display: none;
}

.header-navigation .container .right-side-box {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -5%;
}

.header-navigation .main-navigation {
  float: none;
  text-align: right;
  padding-right: 90px;
}

@media (min-width: 1200px) {
  .header-navigation .main-navigation {
    display: block !important;
  }
}

.header-navigation ul.navigation-box {
  margin: 30px;
  padding: 0;
  list-style: none;
}

.header-navigation ul.navigation-box li a .sub-nav-toggler {
  display: none;
}

.header-navigation ul.navigation-box>li {
  position: relative;
  padding: 40px 0;
  display: inline-block;
  vertical-align: middle;
  /* Second Level Menu */
  /* Thrid Level Menu */
}

.header-navigation ul.navigation-box>li+li {
  margin-left: 70px;
}

.header-navigation ul.navigation-box>li:first-child {
  padding-left: 0;
}

.header-navigation ul.navigation-box>li:last-child {
  padding-right: 0;
}

.header-navigation ul.navigation-box>li>a {
  padding: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: #74727a;
  position: relative;
}

.header-navigation ul.navigation-box>li.current>a, .header-navigation ul.navigation-box>li:hover>a {
  color: #2a2833;
  text-shadow: 1px 0 0 rgba(42, 40, 51, 0.8);
}

.header-navigation ul.navigation-box>li>.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 911;
  float: left;
  min-width: 220px;
  padding: 0px 0px;
  text-align: left;
  list-style: none;
  background-color: #2a2833;
  background-clip: padding-box;
  opacity: 0;
  border-radius: 0px;
  visibility: hidden;
  -webkit-transition: opacity .4s ease, visibility .4s ease;
  transition: opacity .4s ease, visibility .4s ease;
  -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
  .header-navigation ul.navigation-box>li>.sub-menu {
    display: block !important;
  }
}

.header-navigation ul.navigation-box>li>.sub-menu.right-align {
  left: auto;
  right: 0;
}

.header-navigation ul.navigation-box>li>.sub-menu.center-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.header-navigation ul.navigation-box>li>.sub-menu>li {
  display: block;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>.sub-menu>li+li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.header-navigation ul.navigation-box>li>.sub-menu>li>a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding: 12px 30px;
  display: block;
  line-height: 26px;
  white-space: nowrap;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>.sub-menu>li:hover>a {
  color: #fff;
  background: #ffa065;
}

.header-navigation ul.navigation-box>li:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

.header-navigation ul.navigation-box>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
}

.header-navigation ul.navigation-box>li>ul>li {
  /* no more nested showen */
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu {
  position: absolute;
  top: 0%;
  left: 100%;
  z-index: 911;
  float: left;
  min-width: 220px;
  padding: 0px 0px;
  text-align: left;
  list-style: none;
  background-color: #2a2833;
  background-clip: padding-box;
  opacity: 0;
  border-radius: 0px;
  visibility: hidden;
  -webkit-transition: opacity .4s ease, visibility .4s ease;
  transition: opacity .4s ease, visibility .4s ease;
  -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
  .header-navigation ul.navigation-box>li>ul>li>.sub-menu {
    display: block !important;
  }
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu.right-align {
  left: auto;
  right: 100%;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu.center-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li {
  display: block;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li+li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li>a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding: 12px 30px;
  display: block;
  line-height: 26px;
  white-space: nowrap;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li:hover>a {
  color: #fff;
  background: #ffa065;
}

.header-navigation ul.navigation-box>li>ul>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
}

.header-navigation ul.navigation-box>li>ul>li ul {
  display: block;
}

.stricked-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  -webkit-box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.stricked-menu ul.navigation-box>li {
  padding-top: 18px;
  padding-bottom: 18px;
}

.stricked-menu .stricky-fixed {
  opacity: 1;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.site-header {
  position: relative;
}

.site-header__header-one {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 91;
  height: auto;
}

.site-header__header-one .header-navigation .main-navigation {
  padding-right: 0;
  text-align: center;
}

.site-header__header-two .header-navigation ul.navigation-box>li>a {
  color: #ffffff;
}

.site-header__header-two .header-navigation ul.navigation-box>li.current>a, .site-header__header-two .header-navigation ul.navigation-box>li:hover>a {
  color: #ffffff;
  text-shadow: 1px 0 0 rgba(255, 255, 255, 0.8);
}

.site-header__header-two .header__cta-btn {
  color: #fff;
  background-image: none;
  border-radius: 5px;
  overflow: hidden;
}

.site-header__header-two .header__cta-btn:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 2px solid;
  -o-border-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
  border-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-image-slice: 1;
}

.site-header__header-two .header__cta-btn:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
  background-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  z-index: 0;
}

.site-header__header-two .header__cta-btn span {
  z-index: 2;
}

.site-header__header-two .header__cta-btn:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.site-header__header-two .header__cta-btn:hover:after {
  opacity: 1;
}

.site-header__header-two .stricked-menu {
  background-color: #2a2833;
}

/*
* 3. banner styles
*/

.feature {
  width: 100vw;
  padding: 15rem 0;
}

.feature .section-heading {
  margin-bottom: 5rem;
}

.feature__box {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.feature__box--2, .feature__box--4 {
  margin-top: 10rem;
}

.feature__box--2:hover, .feature__box--4:hover {
  margin-top: 0;
}

.feature__box--1:hover, .feature__box--3:hover {
  margin-top: 10rem;
}

.feature__box--1 .feature__box__wrapper {
  background-color: #efdff7;
}

.feature__box--2 .feature__box__wrapper {
  background-color: #ffe8c6;
}

.feature__box--3 .feature__box__wrapper {
  background-color: #d0f3f2;
}

.feature__box--4 .feature__box__wrapper {
  background-color: #ffdae8;
}

.feature__box .icon {
  width: 24.2rem;
  height: 24.2rem;
  margin: 0 auto;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature__box .icon-1 {
  background: #9341d4;
  background: linear-gradient(45deg, #9341d4 0%, #d98efb 100%);
  filter: drop-shadow(0px 40px 15px rgba(147, 65, 212, 0.4));
  -webkit-filter: drop-shadow(0px 40px 15px rgba(147, 65, 212, 0.4));
  -moz-filter: drop-shadow(0px 40px 15px rgba(147, 65, 212, 0.4));
}

.feature__box .icon-2 {
  background: #ffdb8d;
  background: linear-gradient(45deg, #ffb16b 0%, #ffdb8d 100%);
  filter: drop-shadow(0px 40px 15px rgba(255, 186, 114, 0.4));
  -webkit-filter: drop-shadow(0px 40px 15px rgba(255, 186, 114, 0.4));
  -moz-filter: drop-shadow(0px 40px 15px rgba(255, 186, 114, 0.4));
}

.feature__box .icon-3 {
  background: #63e8e4;
  background: linear-gradient(45deg, #19a5a1 0%, #63e8e4 100%);
  filter: drop-shadow(0px 40px 15px rgba(79, 188, 186, 0.4));
  -webkit-filter: drop-shadow(0px 40px 15px rgba(79, 188, 186, 0.4));
  -moz-filter: drop-shadow(0px 40px 15px rgba(79, 188, 186, 0.4));
}

.feature__box .icon-4 {
  background: #ff8aaf;
  background: linear-gradient(45deg, #f2026b 0%, #ff8aaf 100%);
  filter: drop-shadow(0px 40px 15px rgba(245, 69, 144, 0.4));
  -webkit-filter: drop-shadow(0px 40px 15px rgba(245, 69, 144, 0.4));
  -moz-filter: drop-shadow(0px 40px 15px rgba(245, 69, 144, 0.4));
}

.feature__box .icon i {
  font-size: 8rem;
  color: white;
}

.feature__box__wrapper {
  width: 100%;
  padding: 5.7rem 0.5rem 0.5rem 0.5rem;
  border-radius: 2.5rem;
  margin-top: 5rem;
}

.feature__box--content {
  background-color: white;
  position: relative;
  padding: 3.7rem 2rem 2.1rem 2rem;
  border-radius: 2rem;
  text-align: center;
}

.feature__box--content::before {
  content: "";
  width: 4.8rem;
  height: 2.2rem;
  /* background: url(../images/arrow.png) no-repeat; */
  background-size: cover;
  display: block;
  position: absolute;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.feature__box--content h3 {
  font-size: 2.2rem;
  font-weight: 600;
  width: 80%;
  margin: 0 auto 3.4rem auto;
}

@media (max-width: 991.98px) {
  .feature__box {
    margin-top: 5rem !important;
  }
}

@media (max-width: 575.98px) {
  .feature {
    padding: 10rem 2rem;
  }
}

.banner-one {
  position: relative;
  /* background-image: url(../images/background/banner-bg-1-1.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.banner-one .container {
  padding-top: 350px;
  padding-bottom: 230px;
  position: relative;
}

.banner-one__title {
  margin: 0;
  color: #2a2833;
  font-weight: 300;
  font-size: 40px;
  line-height: 1.1em;
  letter-spacing: -.06em;
}

.banner-one__title span {
  font-weight: 600;
  color: #0885B3;
}

.banner-one__text {
  margin: 0;
  font-size: 20px;
  line-height: 36px;
  color: #74727a;
  margin-top: 25px;
  margin-bottom: 40px;
}

.banner-one__btn {
  padding: 18px 55px;
}

.brincos {
  position: absolute;
  bottom: 0;
  right: 0;
  height: auto;
}

/*.banner-one__moc:before {
  content: '';
  width: 639px;
  height: 639px;
  background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
  -webkit-box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.8);
          box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.8);
  border-radius: 50%;
  position: absolute;
  top: -14%;
  left: -24%;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}*/

.brincos img {
  height: auto;
  position: relative;
  /*  top: 10px;
  left: 450px;*/
  -webkit-animation: featureImgBounce 2s ease-in-out 0s infinite alternate;
  animation: featureImgBounce 2s ease-in-out 0s infinite alternate;
}

[class*=banner-one__shape-] {
  position: absolute;
  border-radius: 50%;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.banner-one__shape-1 {
  background-image: linear-gradient(40deg, #9CB437 0%, #9CB437 100%);
  -webkit-box-shadow: 0px 20px 60px 0px rgba(156, 180, 55, 0.8);
  box-shadow: 0px 20px 60px 0px rgba(156, 180, 55, 0.8);
  width: 120px;
  height: 120px;
  top: 5%;
  left: 2%;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-perspective: 120px;
  perspective: 120px;
}

.banner-one__shape-2 {
  background-image: linear-gradient(40deg, #0885B3 0%, #0885B3 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(8, 133, 179, 0.8);
  box-shadow: 0px 20px 40px 0px rgba(8, 133, 179, 0.8);
  width: 85px;
  height: 85px;
  top: 12%;
  left: 37%;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-perspective: 85px;
  perspective: 85px;
}

.banner-one__shape-3 {
  background-image: linear-gradient(40deg, #AE2F72 0%, #AE2F72 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(174, 47, 114, 0.8);
  box-shadow: 0px 20px 40px 0px rgba(174, 47, 114, 0.8);
  width: 85px;
  height: 85px;
  bottom: 12%;
  left: 6%;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
  -webkit-perspective: 85px;
  perspective: 85px;
}

.banner-one__shape-4 {
  background-image: linear-gradient(40deg, #DFA444 0%, #DFA444 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(223, 164, 68, 0.8);
  box-shadow: 0px 20px 40px 0px rgba(255, 164, 68, 0.8);
  width: 95px;
  height: 95px;
  top: 20%;
  right: 4%;
  -webkit-animation-duration: 12s;
  animation-duration: 12s;
  -webkit-perspective: 85px;
  perspective: 85px;
}

@-webkit-keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

@keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

.banner-two {
  position: relative;
  background-color: #2a2833;
  /* background-image: url(../images/background/banner-2-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  margin-bottom: 120px;
}

.banner-two .container {
  padding-top: 260px;
  padding-bottom: 120px;
  position: relative;
}

.banner-two__title {
  margin: 0;
  color: #fff;
  font-weight: 300;
  font-size: 90px;
  line-height: 1em;
  letter-spacing: -.06em;
}

.banner-two__title span {
  font-weight: 500;
}

.banner-two__text {
  margin: 0;
  font-size: 20px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 25px;
  margin-bottom: 40px;
}

.banner-two__btn {
  color: #fff;
  background-image: none;
  border-radius: 5px;
  overflow: hidden;
  padding: 16px 55px;
}

.banner-two__btn:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 2px solid;
  -o-border-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
  border-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-image-slice: 1;
}

.banner-two__btn:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
  background-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  z-index: 0;
}

.banner-two__btn span {
  z-index: 2;
}

.banner-two__btn:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.banner-two__btn:hover:after {
  opacity: 1;
}

.banner-two__moc {
  position: absolute;
  bottom: 0;
  right: 0px;
}

.banner-two__moc img {
  position: relative;
}

[class*=banner-two__shape-] {
  position: absolute;
  border-radius: 50%;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.banner-two__shape-1 {
  background-image: linear-gradient(40deg, #0db8ff 0%, #f332ff 100%);
  width: 143px;
  height: 143px;
  top: 5%;
  left: 3%;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-perspective: 150px;
  perspective: 150px;
}

.banner-two__shape-2 {
  background-image: linear-gradient(40deg, #39aeff 0%, #3cff53 100%);
  width: 85px;
  height: 85px;
  top: 12%;
  left: 37%;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-perspective: 85px;
  perspective: 85px;
}

.banner-two__shape-3 {
  background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
  width: 85px;
  height: 85px;
  bottom: 12%;
  left: 6%;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
  -webkit-perspective: 85px;
  perspective: 85px;
}

.banner-two__shape-4 {
  background-image: linear-gradient(40deg, #ff703e 0%, #ffec4e 100%);
  width: 85px;
  height: 85px;
  top: 20%;
  right: 4%;
  -webkit-animation-duration: 12s;
  animation-duration: 12s;
  -webkit-perspective: 85px;
  perspective: 85px;
}

/*
* 4. blog styles
*/

.blog-one {
  padding: 120px 0;
}

.blog-one__single {
  position: relative;
  margin-bottom: 30px;
}

.blog-one__single:hover .blog-one__more-link {
  color: #fff;
  opacity: 1;
}

.blog-one__single:hover .blog-one__image>img {
  opacity: 0.5;
}

.blog-one__single:hover .blog-one__content {
  -webkit-box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
}

.blog-one__image {
  position: relative;
  background-color: #2a2833;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.blog-one__image>img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-one__content {
  border-style: solid;
  border-width: 1px;
  border-color: #efefef;
  background-color: white;
  border-top: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 50px;
  padding-top: 45px;
  padding-bottom: 40px;
}

.blog-one__meta {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-one__meta li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1em;
}

.blog-one__meta li+li:before {
  content: '.';
  color: #74727a;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
}

.blog-one__meta li a {
  color: #74727a;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-one__meta li a:hover {
  color: #ff4eb5;
}

.blog-one__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #2a2833;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 15px;
}

.blog-one__title a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-one__title a:hover {
  color: #ff4eb5;
}

.blog-one__link {
  color: #ff4eb5;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: .2em;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-one__link:hover {
  color: #2a2833;
}

.blog-one__more-link {
  font-size: 33px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: color .4s ease, opacity .4s ease;
  transition: color .4s ease, opacity .4s ease;
}

.blog-one .post-pagination {
  margin-top: 40px;
}

.blog-details {
  padding: 120px 0;
}

.blog-details .blog-one__single {
  margin-bottom: 0;
}

.blog-details .blog-one__title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2em;
  margin-bottom: 28px;
}

.blog-details .blog-one__content {
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.blog-details .blog-one__text {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 34px;
}

.blog-details .blog-one__text+.blog-one__text {
  margin-top: 30px;
}

.blog-details__content-title {
  margin: 0;
  color: #2a2833;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 40px;
}

.blog-details .share-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 50px;
  border: 1px solid #efefef;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.blog-details .share-block .left-block p {
  margin: 0;
  color: #2a2833;
  font-size: 24px;
  font-weight: 400;
}

.blog-details .share-block .left-block p a {
  color: #74727a;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  margin-left: 5px;
  letter-spacing: 0;
}

.blog-details .share-block .left-block p a:hover {
  color: #2a2833;
}

.blog-details .share-block .social-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-details .share-block .social-block a {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #ff4eb5;
  text-align: center;
  line-height: 56px;
  color: #fff;
  background-size: 200% auto;
  font-size: 18px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-details .share-block .social-block a+a {
  margin-left: 10px;
}

.blog-details .share-block .social-block a:hover {
  background-position: right center;
}

.blog-details .share-block .social-block a.fa-facebook-square {
  background-image: linear-gradient(40deg, #ffa95c 0%, #ff43c0 51%, #ffa95c 100%);
}

.blog-details .share-block .social-block a.fa-twitter {
  background-image: linear-gradient(40deg, #0db8ff 0%, #f332ff 51%, #0db8ff 100%);
}

.blog-details .share-block .social-block a.fa-instagram {
  background-image: linear-gradient(40deg, #39aeff 0%, #3cff53 51%, #39aeff 100%);
}

.blog-details .share-block .social-block a.fa-pinterest-p {
  background-image: linear-gradient(40deg, #ff703e 0%, #ffec4e 51%, #ff703e 100%);
}

.blog-details__author {
  border: 1px solid #efefef;
  padding: 60px 60px;
  margin-top: 50px;
  margin-bottom: 60px;
  border-radius: 5px;
}

.blog-details__author-image, .blog-details__author-content {
  display: table-cell;
  vertical-align: top;
}

.blog-details__author-image {
  width: 169px;
}

.blog-details__author-image>img {
  width: 100%;
  border-radius: 5px;
}

.blog-details__author-content {
  padding-left: 40px;
}

.blog-details__author-content h3, .blog-details__author-content p {
  margin: 0;
}

.blog-details__author-content h3 {
  color: #2a2833;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.blog-details__author-content p {
  font-size: 16px;
  line-height: 30px;
  color: #74727a;
  margin-bottom: 0px;
}

.blog-details__author-content a {
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  color: #ff4eb5;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-details__author-content a:hover {
  color: #2a2833;
}

.comment-one {
  margin-bottom: 60px;
}

.comment-one__single {
  border-bottom: 1px solid #efefef;
  padding-bottom: 50px;
}

.comment-one__single+.comment-one__single {
  margin-top: 60px;
}

.comment-one__image, .comment-one__content {
  display: table-cell;
  vertical-align: top;
}

.comment-one__image {
  width: 90px;
}

.comment-one__image .inner-block {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.comment-one__image .inner-block>img {
  width: 100%;
}

.comment-one__content {
  padding-left: 40px;
}

.comment-one__author {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: #2a2833;
}

.comment-one__date {
  color: #ff4eb5;
  font-size: 16px;
  font-weight: 400;
  line-height: 1em;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 25px;
}

.comment-one__date-sep {
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
  margin-right: 12px;
}

.comment-one__text {
  color: #74727a;
  font-size: 16px;
  line-height: 34px;
  margin: 0;
}

.comment-one__reply {
  padding: 8px 30px;
}

.comment-one__top-left, .comment-one__top-right {
  display: table-cell;
  vertical-align: top;
}

.reply-form input, .reply-form textarea {
  border: none;
  outline: none;
  width: 100%;
  display: block;
  height: 70px;
  color: #74727a;
  font-size: 16px;
  padding-left: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: #efefef;
  border-radius: 5px;
  background-color: #fefbf4;
  margin-bottom: 30px;
}

.reply-form input::-webkit-input-placeholder, .reply-form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #74727a;
}

.reply-form input::-moz-placeholder, .reply-form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #74727a;
}

.reply-form input:-ms-input-placeholder, .reply-form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #74727a;
}

.reply-form input:-moz-placeholder, .reply-form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #74727a;
}

.reply-form textarea {
  height: 232px;
  padding-top: 20px;
}

.blog-one__home {
  padding-bottom: 90px;
}

/*
* 5. sidebar styles
*/

@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}

.sidebar__title {
  margin: 0;
  color: #2a2833;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.sidebar__single:not(.sidebar__search) {
  padding: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: #efefef;
  border-radius: 5px;
}

@media (max-width: 1199px) {
  .sidebar__single:not(.sidebar__search) {
    padding: 30px;
  }
}

.sidebar__single+.sidebar__single {
  margin-top: 30px;
}

.sidebar__post__single+.sidebar__post__single {
  margin-top: 30px;
}

.sidebar__post-image, .sidebar__post-content {
  display: table-cell;
  vertical-align: middle;
}

.sidebar__post-image {
  width: 60px;
}

.sidebar__post-image .inner-block {
  width: 100%;
}

.sidebar__post-image .inner-block>img {
  width: 100%;
  border-radius: 50%;
}

.sidebar__post-content {
  padding-left: 20px;
}

.sidebar__post-title {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #74727a;
}

.sidebar__post-title a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__post-title a:hover {
  color: #2a2833;
}

.sidebar__category-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar__category-list-item {
  line-height: 1em;
}

.sidebar__category-list-item a {
  color: #74727a;
  font-size: 16px;
  font-weight: 400;
  display: block;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__category-list-item a:before {
  content: '\f105';
  font-family: 'FontAwesome';
  color: #7b7b8c;
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__category-list-item a:hover {
  color: #ff4eb5;
}

.sidebar__category-list-item+.sidebar__category-list-item {
  margin-top: 27px;
}

.sidebar__tags-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: -10px;
}

.sidebar__tags-list-item {
  display: inline-block;
  vertical-align: middle;
  line-height: 1em;
}

.sidebar__tags-list-item a {
  display: block;
  color: #74727a;
  font-size: 16px;
  font-weight: 400;
  line-height: 1em;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__tags-list-item a:hover {
  color: #ffa065;
}

.sidebar__search-form {
  width: 100%;
  height: 83px;
  background-image: linear-gradient(40deg, #ff4eb5 0%, #ffa065 100%);
  position: relative;
  border-radius: 5px;
}

.sidebar__search-form input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  padding-left: 40px;
  padding-right: 40px;
}

.sidebar__search-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.sidebar__search-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.sidebar__search-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.sidebar__search-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.sidebar__search-form button[type=submit] {
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  right: 50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__search-form button[type=submit] i {
  font-size: 16px;
  color: #fff;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

/*
* 6. footer styles
*/

.site-footer {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-color: white;
  -webkit-box-shadow: 0px -1px 0px 0px #efefef;
  box-shadow: 0px -1px 0px 0px #efefef;
}

.site-footer__upper {
  padding-top: 120px;
  padding-bottom: 120px;
}

.site-footer__bottom {
  background-color: #fff9ff;
}

.site-footer__bottom .inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding-top: 44px;
  padding-bottom: 44px;
}

.site-footer__copy {
  font-size: 16px;
  font-weight: 400;
  color: #74727a;
  margin: 0;
}

.site-footer__copy a {
  color: #ff4eb5;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.site-footer__copy a:hover {
  color: #fff;
}

.site-footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.site-footer__social a {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #ff4eb5;
  text-align: center;
  line-height: 56px;
  color: #fff;
  background-size: 200% auto;
  font-size: 18px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.site-footer__social a+a {
  margin-left: 10px;
}

.site-footer__social a:hover {
  background-position: right center;
}

.site-footer__social a.fa-facebook-square {
  background-image: linear-gradient(40deg, #0885B3 0%, #9CB437 0%, #0885B3 100%);
}

.site-footer__social a.fa-twitter {
  background-image: linear-gradient(40deg, #0885B3 0%, #9CB437 0%, #0885B3 100%);
}

.site-footer__social a.fa-linkedin {
  background-image: linear-gradient(40deg, #0885B3 0%, #9CB437 0%, #0885B3 100%);
}

.site-footer__social a.fa-instagram {
  background-image: linear-gradient(40deg, #0885B3 0%, #9CB437 0%, #0885B3 100%);
}

/* .site-footer__social a.fa-instagram {
  background-image: linear-gradient(40deg, #39aeff 0%, #3cff53 51%, #39aeff 100%);
} */

.site-footer__social a.fa-pinterest-p {
  background-image: linear-gradient(40deg, #ff703e 0%, #ffec4e 51%, #ff703e 100%);
}

@media (min-width: 1200px) {
  .footer-widget__links-wrap {
    padding-right: 55px;
  }
}

.footer-widget__title {
  font-size: 18px;
  font-weight: 500;
  color: #2a2833;
  margin: 0;
  line-height: 1em;
  margin-bottom: 40px;
}

.footer-widget__links {
  margin: 0;
  margin-top: -5px;
}

.footer-widget__links li {
  line-height: 1em;
}

.footer-widget__links li+li {
  margin-top: 20px;
}

.footer-widget__links li a {
  font-size: 16px;
  font-weight: 400;
  color: #74727a;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.footer-widget__links li a:hover {
  color: #ffa065;
}

.footer-widget__contact {
  font-size: 16px;
  font-weight: 400;
  color: #74727a;
  margin: 0;
  line-height: 30px;
}

.footer-widget__contact a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.footer-widget__contact a:hover {
  color: #ff4eb5;
}

.footer-widget__contact+.footer-widget__contact {
  margin-top: 15px;
}

.footer-widget__mailchimp {
  padding-left: 70px;
}

.footer-widget__logo {
  margin-bottom: 40px;
}

/*
* 7. inner-banner styles
*/

.inner-banner {
  position: relative;
  background-color: #fff;
  /* background-image: url(../images/background/inner-banner-bg-1-1.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding-top: 245px;
  padding-bottom: 120px;
}

.inner-banner__title {
  text-align: center;
  line-height: 1em;
  font-size: 46px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  margin-top: 10px;
}

.thm-breadcrumb {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.thm-breadcrumb li {
  font-size: 18px;
  font-weight: 400;
}

.thm-breadcrumb li a {
  color: #ffffff;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.thm-breadcrumb li a:hover {
  color: #ffa065;
}

.thm-breadcrumb li+li::before {
  content: '.';
  color: #fff;
  margin-left: 15px;
  margin-right: 15px;
}

/*
* 8. video styles
*/

.video-one {
  position: relative;
}

.video-one__bg {
  position: absolute;
  bottom: 0%;
  left: 0;
}

.video-one .container {
  position: relative;
  padding: 15px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ffa95c), to(#ff43c0));
  background-image: linear-gradient(0deg, #ffa95c 0%, #ff43c0 100%);
  border-radius: 7px;
  z-index: 10;
}

.video-one__box {
  position: relative;
  border-radius: 7px;
}

.video-one__box>img {
  width: 100%;
  border-radius: 7px;
}

.video-one__box:hover .video-one__content {
  background-color: rgba(42, 40, 51, 0.2);
}

.video-one__content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(42, 40, 51, 0.6);
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  border-radius: 7px;
}

.video-one__btn {
  width: 106px;
  height: 106px;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), color-stop(51%, #ffa065), to(#ff4eb5));
  background-image: linear-gradient(to right, #ff4eb5 0%, #ffa065 51%, #ff4eb5 100%);
  background-size: 200% auto;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(42, 40, 51, 0.8);
  box-shadow: 0px 20px 40px 0px rgba(42, 40, 51, 0.8);
}

.video-one__btn i {
  font-size: 24px;
  color: #fff;
  line-height: 106px;
}

.video-one__btn:hover {
  background-position: right center;
}

.video-one__btn:hover i {
  color: #fff;
}

.video-one__title {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  margin-top: 20px;
}

.video-one__title span {
  font-weight: 700;
}

/*
* 9. brand styles
*/

.brand-one__carousel {
  padding-left: 48px;
  padding-right: 48px;
  border-bottom: 1px solid #efefef;
  padding-top: 119px;
  padding-bottom: 119px;
}

.brand-one__carousel img {
  opacity: 0.3;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

.brand-one__carousel img:hover {
  opacity: 1;
}

/*
* 10. mailchimp styles
*/

.mailchimp-one {
  padding-top: 110px;
  padding-bottom: 120px;
  background-image: linear-gradient(50deg, #9CB437 0%, #9CB437 100%);
}

.mailchimp-one .block-title__title {
  color: #fff;
}

.mailchimp-one__mc-form {
  width: 100%;
  max-width: 692px;
  height: 91px;
  background-color: #fff;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.mailchimp-one__mc-form input[type="email"] {
  border: none;
  outline: none;
  background-color: transparent;
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  font-size: 16px;
  color: #74727a;
  font-weight: 400;
}

.mailchimp-one__mc-form input[type="email"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #74727a;
}

.mailchimp-one__mc-form input[type="email"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #74727a;
}

.mailchimp-one__mc-form input[type="email"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #74727a;
}

.mailchimp-one__mc-form input[type="email"]:-moz-placeholder {
  /* Firefox 18- */
  color: #74727a;
}

.mailchimp-one__mc-form button[type=submit] {
  border: none;
  outline: none;
  width: 61px;
  height: 61px;
  background-image: -webkit-gradient(linear, left top, right top, from(#0885B3), color-stop(51%, #ffa065), to(#ff4eb5));
  background-image: linear-gradient(to right, #0885B3 0%, #0885B3 51%, #0885B3 100%);
  background-size: 200% auto;
  border-radius: 50%;
  font-size: 22px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.mailchimp-one__mc-form button[type=submit]:hover {
  background-position: right center;
}

.mailchimp-one__mc-form.errored input {
  background-color: red;
  color: #fff;
}

.mailchimp-one__mc-form.errored input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.mailchimp-one__mc-form.errored input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.mailchimp-one__mc-form.errored input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.mailchimp-one__mc-form.errored input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.mc-form__response p {
  background-color: #fff;
  font-size: 11px;
  margin: 0;
  color: #2a2833;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.mc-form__response.successed p {
  background-color: green;
}

/*
* 11. accrodion styles
*/

.faq-one {
  padding: 0 0 120px;
  position: relative;
}

.faq-one__bg {
  position: absolute;
  bottom: 4%;
  right: 0;
}

.faq-one .container {
  position: relative;
}

@media (min-width: 992px) {
  .faq-one .container {
    max-width: 920px;
  }
}

.faq-accrodion .accrodion {
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.faq-accrodion .accrodion:before {
  content: '';
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  border-radius: 7px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ff4eb5), to(#ffa065));
  background-image: linear-gradient(0deg, #ff4eb5 0%, #ffa065 100%);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.faq-accrodion .accrodion+.accrodion {
  margin-top: 20px;
}

.faq-accrodion .accrodion.active {
  -webkit-box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.3);
  box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.3);
}

.faq-accrodion .accrodion.active .accrodion-inner {
  margin: 0 2px;
}

.faq-accrodion .accrodion.active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.faq-accrodion .accrodion.active .accrodion-title:before {
  content: '\f068';
  color: #ff4eb5;
}

.faq-accrodion .accrodion.active .accrodion-title {
  padding-bottom: 0;
}

.faq-accrodion .accrodion.active .accrodion-title h4 {
  color: #ff4eb5;
}

.faq-accrodion .accrodion .accrodion-inner {
  background-color: #fff;
  padding: 32px 0;
  border-style: solid;
  border-width: 1px;
  border-color: #efefef;
  border-radius: 7px;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.faq-accrodion .accrodion .accrodion-title {
  padding: 0px 40px;
  cursor: pointer;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

@media (max-width: 375px) {
  .faq-accrodion .accrodion .accrodion-title {
    padding-right: 50px;
  }
}

.faq-accrodion .accrodion .accrodion-title:before {
  content: '\f067';
  font-family: 'FontAwesome';
  font-size: 14px;
  color: #2a2833;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 40px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.faq-accrodion .accrodion .accrodion-title h4 {
  margin: 0;
  color: #2a2833;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 375px) {
  .faq-accrodion .accrodion .accrodion-title h4 {
    line-height: 1.4em;
  }
}

.faq-accrodion .accrodion .accrodion-content .inner {
  padding: 0 40px;
  padding-top: 25px;
  padding-bottom: 0px;
}

.faq-accrodion .accrodion .accrodion-content p {
  margin: 0;
  color: #74727a;
  font-size: 16px;
  line-height: 30px;
}

/*
* 12. fact styles
*/

.fact-one {
  padding-top: 120px;
}

.fact-one .container {
  z-index: 10;
  position: relative;
}

.fact-one__single {
  -webkit-box-shadow: 0px 20px 60px 0px rgba(42, 40, 51, 0.05);
  box-shadow: 0px 20px 60px 0px rgba(42, 40, 51, 0.05);
  width: 220px;
  height: 220px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  position: relative;
}

.fact-one__single:before {
  content: '';
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  border-radius: 50%;
  background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
}

.fact-one__inner {
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.3);
  box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.3);
  border-radius: 50%;
  position: relative;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.fact-one__inner h3, .fact-one__inner p {
  margin: 0;
}

.fact-one__inner h3 {
  color: #2a2833;
  font-weight: 400;
  font-size: 40px;
  line-height: 1em;
}

.fact-one__inner p {
  text-transform: uppercase;
  letter-spacing: .2em;
  font-size: 12px;
  font-weight: 500;
  color: #74727a;
  line-height: 1em;
  margin-top: 10px;
}

.fact-one [class*=col-]:nth-child(1) .fact-one__inner {
  -webkit-box-shadow: inset 0px 20px 40px 0px rgba(255, 118, 142, 0.3);
  box-shadow: inset 0px 20px 40px 0px rgba(255, 118, 142, 0.3);
}

.fact-one [class*=col-]:nth-child(1) .fact-one__single:before {
  background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
}

.fact-one [class*=col-]:nth-child(2) .fact-one__inner {
  -webkit-box-shadow: inset 0px 20px 60px 0px rgba(141, 142, 255, 0.3);
  box-shadow: inset 0px 20px 60px 0px rgba(141, 142, 255, 0.3);
}

.fact-one [class*=col-]:nth-child(2) .fact-one__single:before {
  background-image: linear-gradient(40deg, #0db8ff 0%, #f332ff 100%);
}

.fact-one [class*=col-]:nth-child(3) .fact-one__inner {
  -webkit-box-shadow: inset 0px 20px 40px 0px rgba(58, 212, 173, 0.3);
  box-shadow: inset 0px 20px 40px 0px rgba(58, 212, 173, 0.3);
}

.fact-one [class*=col-]:nth-child(3) .fact-one__single:before {
  background-image: linear-gradient(40deg, #39aeff 0%, #3cff53 100%);
}

.fact-one [class*=col-]:nth-child(4) .fact-one__inner {
  -webkit-box-shadow: inset 0px 20px 40px 0px rgba(255, 178, 71, 0.3);
  box-shadow: inset 0px 20px 40px 0px rgba(255, 178, 71, 0.3);
}

.fact-one [class*=col-]:nth-child(4) .fact-one__single:before {
  background-image: linear-gradient(40deg, #ff703e 0%, #ffec4e 100%);
}

/*
* 13. testimonials styles
*/

.testimonials-one {
  background-color: #fff9ff;
  padding-top: 110px;
  padding-bottom: 160px;
}

.testimonials-one .container {
  position: relative;
}

.testimonials-one__carousel {
  position: relative;
  padding: 2px;
  border-radius: 7px;
}

.testimonials-one__carousel:before {
  content: '';
  border-radius: 7px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ff4eb5), to(#ffa065));
  background-image: linear-gradient(0deg, #ff4eb5 0%, #ffa065 100%);
}

.testimonials-one__carousel-outer {
  position: relative;
  width: 100%;
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.3);
  box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.3);
}

.testimonials-one__carousel .owl-item img {
  width: auto;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.testimonials-one__single {
  padding-top: 71px;
  padding-bottom: 71px;
  background-color: #fff;
  text-align: center;
  border-radius: 7px;
}

.testimonials-one__single h3, .testimonials-one__single p, .testimonials-one__single span {
  display: block;
  margin: 0;
}

.testimonials-one__single p {
  color: #74727a;
  font-size: 24px;
  line-height: 46px;
  font-weight: 300;
  margin-bottom: 35px;
}

.testimonials-one__single h3 {
  color: #2a2833;
  font-weight: 500;
  font-size: 24px;
  line-height: 1em;
  margin-bottom: 10px;
}

.testimonials-one__single span {
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: .2em;
  color: #ff4eb5;
  text-transform: uppercase;
}

[class*=testimonials-one__nav-] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 61px;
  height: 61px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  color: #2a2833;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

[class*=testimonials-one__nav-]:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background-image: linear-gradient(68deg, #ff4eb5 0%, #ffa065 100%);
  opacity: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

[class*=testimonials-one__nav-] i {
  font-size: 22px;
  position: relative;
}

[class*=testimonials-one__nav-]:hover {
  color: #fff;
  -webkit-box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.8);
  box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.8);
}

[class*=testimonials-one__nav-]:hover:before {
  opacity: 1;
}

.testimonials-one__nav-left {
  right: calc(100% + 90px);
}

.testimonials-one__nav-right {
  left: calc(100% + 90px);
}

.testimonials-one__carousel__shape-one, .testimonials-one__carousel__shape-two {
  width: 100%;
  height: 100%;
  background-color: #fff9ff;
  position: absolute;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transform: scaleX(0.9);
  transform: scaleX(0.9);
  bottom: -20px;
  opacity: .2;
  border-radius: 7px;
  background-image: linear-gradient(68deg, #ff4eb5 0%, #ffa065 100%);
}

.testimonials-one__carousel__shape-one:after, .testimonials-one__carousel__shape-two:after {
  content: '';
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  border-radius: 7px;
  background-color: #fff;
}

.testimonials-one__carousel__shape-two {
  -webkit-transform: scaleX(0.85);
  transform: scaleX(0.85);
  bottom: -40px;
}

/*
* 14. pricing styles
*/

.pricing-one {
  padding-top: 110px;
  padding-bottom: 90px;
  /* The switch - the box around the slider */
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}

.pricing-one .switch {
  position: relative;
  display: inline-block;
  width: 85px;
  height: 40px;
  vertical-align: middle;
  margin: 0;
}

.pricing-one .switch input {
  display: block;
}

.pricing-one .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
  border-style: solid;
  border-width: 1px;
  border-color: #efefef;
  background-color: white;
}

.pricing-one .slider:before {
  position: absolute;
  content: "";
  height: 27px;
  width: 27px;
  left: 6px;
  bottom: 6px;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  background-image: linear-gradient(40deg, #ff4eb5 0%, #ffa065 100%);
  -webkit-transition: .4s;
  transition: .4s;
}

.pricing-one input:focus+.slider {
  -webkit-box-shadow: 0 0 1px #2196F3;
  box-shadow: 0 0 1px #2196F3;
}

.pricing-one .switch.off .slider:before {
  -webkit-transform: translateX(44px);
  transform: translateX(44px);
}

.pricing-one .slider.round {
  border-radius: 34px;
}

.pricing-one .slider.round:before {
  border-radius: 50%;
}

.pricing-one ul.switch-toggler-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 50px;
}

.pricing-one ul.switch-toggler-list li a {
  font-size: 18px;
  font-weight: 400;
  color: #74727a;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}

.pricing-one ul.switch-toggler-list li.active a {
  color: #2a2833;
}

.pricing-one [class*=col-] {
  margin-bottom: 30px;
}

.pricing-one [class*=col-]+[class*=col-] {
  border-left: 1px solid #efefef;
}

.pricing-one [class*=col-]:nth-child(2) .pricing-one__btn {
  background-image: linear-gradient(40deg, #39aefe 0%, #3cff53 100%);
}

.pricing-one [class*=col-]:nth-child(3) .pricing-one__btn {
  background-image: linear-gradient(40deg, #0db8ff 0%, #f033ff 100%);
}

.pricing-one .tabed-content #year, .pricing-one .tabed-content #month {
  display: none;
}

.pricing-one__single {
  padding-left: 40px;
  padding-right: 40px;
}

.pricing-one__single h3, .pricing-one__single p, .pricing-one__single ul, .pricing-one__single span {
  display: block;
  margin: 0;
}

.pricing-one__single h3 {
  color: #ff4eb5;
  font-size: 60px;
  letter-spacing: -0.04em;
  line-height: 1em;
  font-weight: 400;
  margin-bottom: 25px;
}

.pricing-one__single p, .pricing-one__single span.tag-line {
  color: #2a2833;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: .2em;
}

.pricing-one__single ul {
  border-top: 1px solid #efefef;
  margin-top: 50px;
  padding-top: 50px;
  margin-bottom: 50px;
}

.pricing-one__single ul li {
  color: #74727a;
  font-size: 16px;
  font-weight: 400;
  line-height: 1em;
}

.pricing-one__single ul li+li {
  margin-top: 30px;
}

.pricing-one__single span.tag-line {
  font-size: 12px;
  color: #74727a;
  margin-top: 30px;
}

.pricing-one__btn {
  padding: 18px 55.5px;
}

/*
* 15. cta styles
*/

.cta-one {
  padding: 165px 0;
  position: relative;
}

.cta-one__bg {
  position: absolute;
  bottom: 4%;
  right: 0;
}

.cta-one .container {
  position: relative;
}

.cta-one .container:before {}

.cta-one__moc {
  position: absolute;
  /* top: -45px;
  left: -10%; */
  -webkit-animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
  animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
}

.cta-one .block-title {
  margin-bottom: 0;
  margin-top: 30px;
  margin-bottom: 45px;
}

.cta-one .block-title__title {
  letter-spacing: -.04em;
}

.cta-one__icon {
  font-size: 62px;
  color: #ff4eb5;
}

.cta-one__text p {
  margin: 0;
  color: #74727a;
  font-size: 24px;
  line-height: 40px;
}

.cta-one ul {
  margin: 0;
  margin-top: 45px;
  margin-bottom: 50px;
}

.cta-one ul li {
  position: relative;
  font-size: 22px;
  color: #74727a;
  padding-left: 30px;
}

.cta-one ul li+li {
  margin-top: 3px;
}

.cta-one ul li i {
  font-size: 18px;
  color: #ffa065;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cta-one .thm-btn {
  padding: 18px 55.5px;
}

.cta-two {
  padding: 20px 0px 60px 0px;
  background-color: #212A3D;
}

.cta-two2 {
  padding: 60px 0px 60px 0px;
  background-color: #fff;
}

.cta-two2 .row {
  margin: 0px;
}

.cta-two .container {
  margin: auto;
}

.cta-two__moc {
  position: relative;
  align-content: center;
  margin: auto;
  -webkit-animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
  animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
}

.cta-two__moc2 {
  position: relative;
  align-content: center;
  margin: auto;
}

.cta-two .block-title {
  margin-top: 30px;
  margin-bottom: 35px;
}

.cta-two .block-title__title {
  letter-spacing: -.04em;
}

.cta-two__icon {
  font-size: 62px;
  color: #ffa065;
}

.cta-two__text {
  margin-bottom: 50px;
}

.cta-two__text p {
  margin: 0;
  color: #74727a;
  font-size: 18px;
  line-height: 34px;
}

.cta-two__text p+p {
  margin-top: 35px;
}

.cta-two .thm-btn {
  padding: 18px 55.5px;
}

@-webkit-keyframes featureImgBounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

@keyframes featureImgBounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

/*
* 16. service styles
*/

.service-one {
  padding-bottom: 90px;
}

.service-one .container {
  position: relative;
  z-index: 10;
}

.service-one__single {
  border-style: solid;
  border-width: 1px;
  border-color: #efefef;
  border-radius: 5px;
  padding: 2px;
  position: relative;
  margin-bottom: 30px;
}

.service-one__single:before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 5px;
  opacity: 0;
  background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.service-one__single h3 {
  margin: 0;
  font-size: 20px;
  line-height: 34px;
  color: #2a2833;
}

.service-one__single h3 a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.service-one__single h3 a:hover {
  color: #ff4eb5;
}

.service-one__single p {
  margin: 0;
  color: #74727a;
  font-size: 16px;
  line-height: 34px;
  margin-top: 35px;
  margin-bottom: 25px;
}

.service-one__single:hover:before {
  opacity: 1;
}

.service-one__link {
  color: #2a2833;
  font-size: 22px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.service-one__link:hover {
  color: #ff4eb5;
}

.service-one__inner {
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  padding-top: 50px;
  padding-bottom: 35px;
}

.service-one__icon {
  width: 108px;
  height: 108px;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.8);
  box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.8);
  border-radius: 5px;
  background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-size: 70px;
  margin-bottom: 30px;
}

.service-one [class*=col-]:nth-child(1) .service-one__single:before, .service-one [class*=col-]:nth-child(1) .service-one__icon {
  background-mage: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
}

.service-one [class*=col-]:nth-child(1) .service-one__icon {
  -webkit-box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.8);
  box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.8);
}

.service-one [class*=col-]:nth-child(2) .service-one__single:before, .service-one [class*=col-]:nth-child(2) .service-one__icon {
  background-image: linear-gradient(40deg, #0db8ff 0%, #f332ff 100%);
}

.service-one [class*=col-]:nth-child(2) .service-one__icon {
  -webkit-box-shadow: 0px 20px 60px 0px rgba(141, 142, 255, 0.8);
  box-shadow: 0px 20px 60px 0px rgba(141, 142, 255, 0.8);
}

.service-one [class*=col-]:nth-child(3) .service-one__single:before, .service-one [class*=col-]:nth-child(3) .service-one__icon {
  background-image: linear-gradient(40deg, #39aeff 0%, #3cff53 100%);
}

.service-one [class*=col-]:nth-child(3) .service-one__icon {
  -webkit-box-shadow: 0px 20px 40px 0px rgba(58, 212, 173, 0.8);
  box-shadow: 0px 20px 40px 0px rgba(58, 212, 173, 0.8);
}

.service-one [class*=col-]:nth-child(4) .service-one__single:before, .service-one [class*=col-]:nth-child(4) .service-one__icon {
  background-image: linear-gradient(40deg, #ff703e 0%, #ffec4e 100%);
}

.service-one [class*=col-]:nth-child(4) .service-one__icon {
  -webkit-box-shadow: 0px 20px 40px 0px rgba(255, 178, 71, 0.8);
  box-shadow: 0px 20px 40px 0px rgba(255, 178, 71, 0.8);
}

/*
* 17. app-shot styles
*/

.app-shot-one {
  padding-top: 120px;
  padding-bottom: 115px;
  position: relative;
}

.app-shot-one__bg {
  position: absolute;
  bottom: 25%;
  left: 0;
}

.app-shot-one .container-fluid {
  max-width: 1595px;
  width: 100%;
  position: relative;
}

.app-shot-one .app-shot-one__carousel {
  padding-top: 100.5px;
  padding-bottom: 100.5px;
  margin-top: -20px;
}

.app-shot-one .app-shot-one__carousel:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -49%) scaleY(1);
  transform: translate(-50%, -49%) scaleY(1);
  /* background-image: url(../images/app-shots/lap-screen-moc.png); */
  background-position: center center;
  background-repeat: no-repeat;
  width: 846px;
  height: 573px;
}

@media (max-width: 1440px) {
  .app-shot-one .app-shot-one__carousel:before {
    -webkit-transform: translate(-50%, -49%) scaleY(0.95);
    transform: translate(-50%, -49%) scaleY(0.95);
  }
}

@media (max-width: 1280px) {
  .app-shot-one .app-shot-one__carousel:before {
    -webkit-transform: translate(-50%, -49%) scale(0.8);
    transform: translate(-50%, -49%) scale(0.8);
  }
}

.app-shot-one .app-shot-one__carousel .owl-dots {
  position: absolute;
  bottom: -80px;
  left: 0;
  text-align: center;
  z-index: 10;
  width: 100%;
}

.app-shot-one .app-shot-one__carousel .owl-dots .owl-dot span {
  width: 7px;
  height: 7px;
  background-color: #1c1d3f;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0.2;
  margin: 0 3px;
  -webkit-transition: opacity .4s ease, background .4s ease, -webkit-transform .4s ease;
  transition: opacity .4s ease, background .4s ease, -webkit-transform .4s ease;
  transition: transform .4s ease, opacity .4s ease, background .4s ease;
  transition: transform .4s ease, opacity .4s ease, background .4s ease, -webkit-transform .4s ease;
}

.app-shot-one .app-shot-one__carousel .owl-dots .owl-dot:hover span, .app-shot-one .app-shot-one__carousel .owl-dots .owl-dot.active span {
  opacity: 1;
  background-color: #1bc9f4;
  -webkit-transform: scale(1.285);
  transform: scale(1.285);
}

.app-shot-one .owl-item img {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.h2, h2 {
  font-size: 1.7rem !important;
}

.site-header__header-one {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 91;
  height: auto;
}

.brincos img {
  height: auto;
  position: relative;
  animation: featureImgBounce 2s ease-in-out 0s infinite alternate;
  max-width: 800px !important;
}

@media screen and (min-width: 800px) and (max-width: 1030px) {
  .brincos img {
    height: auto;
    position: relative;
    animation: featureImgBounce 2s ease-in-out 0s infinite alternate;
    max-width: 650px !important;
    left: 100px;
  }
}

.la-2x {
  font-size: 2em !important;
}

.la-2x-redes {
  font-size: 1em !important;
}

.modal-login .custom-dialog {
  min-height: 600px !important;
  background-color: transparent !important;
}

.modal-login .modal-content {
  background: transparent !important;
  border: none !important;
}